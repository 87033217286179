<template>
    <div class="sidebar">
        <div class="text-center">
            <img src="../assets/mdm_logo_placeholder.png" alt="Logo" class="logo" @click="goToHome" />
        </div>
    <ul class="justify-content-center align-items-center">
        <li v-for="item in imageItemsTop" :key="item.id" @click="goToPage(item.route)">
            <div class="image-container">
                <img :src="item.imageUrlTop" :alt="item.altText" class="icon-image" />
                <span class="image-label">{{ item.altText }}</span>
            </div>
        </li>
        <li v-for="item in imageItemsMid" :key="item.id" @click="goToPage(item.route)">
            <div class="image-container">
                <img :src="item.imageUrlMid" :alt="item.altText" class="icon-image" />
                <span class="image-label">{{ item.altText }}</span>
            </div>
        </li>
        <div class="lower_icons_container">
            <li v-for="item in imageItemsLower" :key="item.id" @click="goToPage(item.route)" class="lower_icons_list">
                <div class="image-container">
                    <img :src="item.imageUrlLower" :alt="item.altText" class="icon-image" />
                    <span class="image-label">{{ item.altText }}</span>
                </div>
            </li>
        </div>
    </ul>
    </div>
</template>

<script>

export default{
    data() {
        return{
            imageItemsTop: [
                //{id: 1, imageUrlTop: require('../assets/register_device_icon.png'), altText: 'Register Device', route: '/register-devices'},
                //{id: 2, imageUrlTop: require('../assets/manage_devices_icon.png'), altText: 'Manage Devices', route: '/device-list'},
                //{id: 2, imageUrlTop: require('../assets/manage_accounts.png'), altText: 'Manage Accounts', route: '/manage-accounts'},
                //{id: 4, imageUrlTop: require('../assets/faq_icon.png'), altText: 'FAQ', route: '/faq'},
            ],
            imageItemsMid: [
                //{id: 1, imageUrlMid: require('../assets/manage_accounts.png'), altText: 'Manage Accounts', route: '/manage-accounts'},
                //{id: 2, imageUrlMid: require('../assets/faq_icon.png'), altText: 'FAQ', route: '/faq'},
            ],
            imageItemsLower: [
                {id: 1, imageUrlLower: require('../assets/home_icon.png'), altText: 'Dashboard', route: '/dashboard'},
                {id: 2, imageUrlLower: '/country_flags/en_US.svg', altText: this.langmode, route: 'langchange'},
                {id: 3, imageUrlLower: require('../assets/profile_icon.png'), altText: 'Log Out', route: '/'},
            ],
            langmode : "ENG",
            data: []
        }
    },
    methods: {
        goToPage(route){
            if (route === 'langchange'){
                this.langswap()
            }
            else{
                this.$router.replace(route);
            }
            
        },
        goToHome(){
            this.$router.replace('/dashboard')
        },
        async onlyLoadAccepted() {
            //Perform API Call
            var data_collection = null;
            if (localStorage.getItem('acceptedArrays') != null) {
                //Put array data here
                data_collection = localStorage.getItem('acceptedArrays');
                
            } 
            else {
                const axios = require('axios');
                let data = JSON.stringify({
                    token: localStorage.token
                });
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: process.env.VUE_APP_BACKEND_URL+'/v1/dashboard/sidebar-view/pos',
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    data : data
                };

                //API request
                axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));  
                    if (response.status === 200){
                        console.log("success");
                        data_collection = response.data.data;
                        var newItemsImageTop = [];
                        var newimageItemsMid = [];
                        if ( data_collection.includes('Register Device') ) {
                            newItemsImageTop.push({id: 1, imageUrlTop: require('../assets/register_device_icon.png'), altText: 'Register Device', route: '/register-devices'});
                        }
                        if (data_collection.includes('Manage Accounts')) {
                            newItemsImageTop.push({id: 2, imageUrlTop: require('../assets/manage_accounts.png'), altText: 'Manage Accounts', route: '/manage-accounts'});
                        }
                        if (data_collection.includes('Manage Devices')) {
                            newItemsImageTop.push({id: 2, imageUrlTop: require('../assets/manage_devices_icon.png'), altText: 'Manage Devices', route: '/manage-devices'});
                        }
                        newimageItemsMid.push({id: 4, imageUrlMid: require('../assets/faq_icon.png'), altText: 'FAQ', route: '/faq'});
                        if (data_collection.includes('Reports')) {
                            newItemsImageTop.push({id: 5, imageUrlTop: require('../assets/report.png'), altText: 'Reports', route: '/reporting'});
                        }
                        //Put array data here
                        this.imageItemsTop = newItemsImageTop;
                        this.imageItemsMid = newimageItemsMid;
                        console.log(this.imageItemsMid);

                        //Push to localStorage so it doesn't have to call the API all the time
                        localStorage.setItem('acceptedArrays', data_collection);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false
                    this.tryagainWarning = true
                    
                });
            }

            var newItemsImageTop = [];

            var newimageItemsMid = [];
            console.log(data_collection);
            if (data_collection){
                //Parse the diff arrays here
                console.log(data_collection);
                 //Add accordingly
                if ( data_collection.includes('Register Device') ) {
                    newItemsImageTop.push({id: 1, imageUrlTop: require('../assets/register_device_icon.png'), altText: 'Register Device', route: '/register-devices'});
                }
                if (data_collection.includes('Manage Accounts')) {
                    newItemsImageTop.push({id: 1, imageUrlTop: require('../assets/manage_accounts.png'), altText: 'Manage Accounts', route: '/manage-accounts'});
                }
                if (data_collection.includes('Manage Devices')) {
                            newItemsImageTop.push({id: 2, imageUrlTop: require('../assets/manage_devices_icon.png'), altText: 'Manage Devices', route: '/manage-devices'});
                }
                newimageItemsMid.push({id: 4, imageUrlMid: require('../assets/faq_icon.png'), altText: 'FAQ', route: '/faq'});
                if (data_collection.includes('Reports')) {
                    newItemsImageTop.push({id: 5, imageUrlTop: require('../assets/report.png'), altText: 'Reports', route: '/reporting'});
                }
                //Put array data here
                this.imageItemsTop = newItemsImageTop;
                this.imageItemsMid = newimageItemsMid;
                console.log(this.imageItemsMid);

                //Push to localStorage so it doesn't have to call the API all the time
                localStorage.setItem('acceptedArrays', data_collection);
            }
            else{
                //Error out here
                console.log("No restrictions found");
            }
            
        },
        iconClicked(item){
            //Change the image icon and then execute the route movement
            if(item.id == 1 && item.imageUrlTop){
                item.imageUrlTop = require('../assets/register_device_navbar.png');
                this.goToPage(item);
            }
        },
        langchange(val){
            this.langmode = val
            //this.imageItemsLower[1].altText = this.langmode
            //this.imageItemsTop[0].altText = this.data[this.langmode]['top'][0];
            //this.imageItemsManageDevice[0].altText = this.data[this.langmode]['manage'][0];
            //this.imageItemsMid[0].altText = this.data[this.langmode]['mid'][1];
            //this.imageItemsMid[1].altText = this.data[this.langmode]['mid'][1];
            //this.imageItemsLower[0].altText = this.data[this.langmode]['lower'][0];
            //this.imageItemsLower[2].altText = this.data[this.langmode]['lower'][1];
            //console.log((this.data[this.langmode]['topUrl']))
            //this.imageItemsLower[1].imageUrlLower = this.data[this.langmode]['langUrl'];
            this.$emit('button-click',this.langmode);
        },
        langswap(){
            var langmode
            if (this.langmode === "ENG"){
                langmode = "BM"
            }
            else{
                langmode = "ENG"
            }
            this.langchange(langmode)
        }
    },
    async mounted() {
        const response = await fetch("/sidenav.json");
        const data = await response.json();
        await this.onlyLoadAccepted().then(() => {
            if (localStorage.token == undefined){
                this.goToPage('/');
            }
            this.data = data;
            this.langchange("ENG");
        });
    },
}
</script>

<style scoped>
    .sidebar {
        height: 106vh;
        width: 80px;
        background-color: #ffd600;
    }

    span{
        font-family: 'Century Gothic Bold';
        font-size: 11px;
        text-align: center;
    }

    .logo {
        width: 35px;
        height: 35px;
        margin-top: 16px;
        margin-bottom: 20px;
        cursor: pointer;
    }

    .image-container {
        height: 60px;
        margin-bottom: 20px;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .image-container:hover{
        background-color: rgba(255, 255, 255, 0.5);
    }

    .icon-image {
        width: 25px; /* Adjust the width and height based on your image dimensions */
        height: 27px;
        margin-bottom: 2px;
    }

    .mandev_icon{
        margin-right: 6px;
    }

    .image-label {
        font-size: 11px;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }
    .lower_icons_container{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: calc(74vh - 258px);
    }
    @media(max-width: 376px){
        .lower_icons_container{
            height: calc(100vh - 180px);
        }
        .image-container{
            margin-bottom: 10px;
        }
        .sidebar{
            width: 50px
        }
        .icon-image{
            width: 20px;
            height: 20px;
        }
        .image-label{
            font-size: 8px;
        }
    }
</style>